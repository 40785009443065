import React, {useEffect, useMemo, useState} from 'react';
import {Box, useTheme, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {HorizontalBarChart} from 'pages/reports/components/charts';
import {MultiselectCheckbox} from 'pages/reports/components/MultiselectCheckbox';
import {IQuestionDetail} from 'shared/services/api/reports/AnswerSummary';
import {useReports} from 'pages/reports/hooks/useReports';

const useStyles = makeStyles(() => ({
  doNotShowOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

interface LinearScaleUniqueMultipleChartProps {
  configs: IQuestionDetail[];
}

export const LinearScaleUniqueMultipleAnswer: React.FC<
  LinearScaleUniqueMultipleChartProps
> = ({configs}) => {
  const {changeDashboardValueFormat} = useReports();
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [datasetValues, setDatasetValues] = useState<number[]>([]);
  const theme = useTheme();
  const classes = useStyles();

  const groupBy = (collection: any, property: string) => {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  };

  const checkboxFilters = useMemo(() => {
    var roleList: any = [];
    configs.forEach((config, i) => {
      config.papeis.reduce((r: any, item) => {
        roleList.push({
          resposta: item.numeroDeRespostas || 0,
          label: item.papel,
          checked: true,
        });

        return true;
      }, {});
    });

    const filtersList = groupBy(roleList, 'label');
    const result = filtersList.map((filter, i) => {
      var respostas: any = [];
      return filter.reduce((result: any, current: any) => {
        respostas.push(current.resposta);
        return Object.assign(result, {
          checked: true,
          label: current.label,
          resposta: respostas,
        });
      }, {});
    });

    return result;
  }, [configs]);

  useEffect(() => {
    const labels = configs.map((label) => label.resposta);
    setChartLabels(labels);
    setDatasetValues(
      configs.map((dataset) =>
        dataset.papeis.reduce((a, b) => {
          return a + (b.numeroDeRespostas || 0);
        }, 0),
      ),
    );
  }, [configs, theme]);

  const barColors = useMemo(() => {
    const countLabels = configs.map((label) => label.resposta).length;

    const colors = [
      '#018781',
      '#00B9AD',
      '#4285F4',
      '#F4405C',
      '#54BDD5',
      '#EB5333',
    ];

    const randomColors = colors.sort(() => 0.5 - Math.random());

    return randomColors.slice(0, countLabels);
  }, [configs]);

  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'space-evenly'}
      alignItems={'flex-start'}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Box>
            <HorizontalBarChart
              labels={chartLabels}
              datasets={[
                {
                  label: 'Respondido',
                  categoryPercentage: 1,
                  barPercentage: 0.6,
                  backgroundColor: barColors,
                  borderColor: barColors,
                  borderWidth: 1,
                  hoverBackgroundColor: barColors,
                  hoverBorderColor: barColors,
                  data: datasetValues,
                },
              ]}
              options={{
                maintainAspectRatio: true,
                responsive: true,
              }}
              width={600}
              height={300}
              showDataLabels={true}
              showAsPercentage={changeDashboardValueFormat}
            />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.doNotShowOnPrint}>
          <Box>
            <MultiselectCheckbox
              options={checkboxFilters}
              onChange={(values) => {
                !values.length
                  ? setDatasetValues([0, 0, 0, 0, 0])
                  : setDatasetValues(() => {
                    const respostas = configs.map(resposta => resposta.papeis.filter(
                      papel => values.find(value => value.label === papel.papel)
                    ));
                                     
                    return respostas.map((dataset) =>
                      dataset.reduce((a, b) => {
                        return a + (b.numeroDeRespostas || 0);
                      }, 0),
                    )
                  }); 
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
